.containBox {
  display: flex;
  flex-direction: column;

  .logo {
    width: 266px;
  }

  .title {
    color: rgba(128, 128, 128, 100);
    margin-top: 13px;
    margin-bottom: 20px;
    font-size: 18px;
    font-style: italic;
    font-family: Roboto-Light;
  }

  .btnBox {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .font {
    left: 121px;
    top: 314px;
    width: 170px;
    height: 100px;
    line-height: 20px;
    border-radius: 6px;
    font-family: PingFangSC-regular;
    font-size: 22px;
  }

  .title1 {
    width: 170px;
    color: #122B88;
    background-color: #C0C0C0;
    padding: 10px 25px 80px;
    border-radius: 8px;
  }

  .divider {
    background-color: black;
  }

  //.btnBox1 {
  //  display: flex;
  //  justify-content: space-around;
  //  width: 90vw;
  //  position: absolute;
  //  left: 35px;
  //  top: 600px;
  //}

  .title2 {
    width: 170px;
    text-align: center;
    color: #920784;
    background-color: #C0C0C0;
    padding: 10px 25px 80px;
    border-radius: 8px;
  }

  .title3 {
    font-size: 35px;
  }

  .title4 {
    font-size: 26px;
  }

  .signBox {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    width: 90vw;

    .leftTitle {
      color: rgba(18, 43, 136, 100);
      font-size: 32px;
      margin-right: 39px;
      width: 35vw;
      margin-left: 20px;
      text-align: center;
      height: 100px;
      font-family: Roboto-Medium;
    }

    .leftTitleIntp {
      color: rgba(146, 7, 132, 100);
      font-size: 26px;
      margin-right: 39px;
      width: 35vw;
      margin-left: 20px;
      text-align: center;
      height: 100px;
      font-style: italic;
      font-family: Roboto-Medium;
    }

    .rightBox {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        margin-bottom: 20px;
        font-size: 28px;
        height: 48px;
        width: 160px;
        font-family: Roboto-Bold;
      }

      .btnSignUp {
        margin-bottom: 20px;
        border: 1px solid rgba(128,128,128);
        font-size: 16px;
        height: 35px;
        border-radius: 6px;
        color: #404040;
      }
    }
  }

  .providerTown {
    text-align: center;

    .desc {
      font-family: Roboto-Light;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .homeBtn {
      position: absolute;
      bottom: 14px;
      left: 35vw;
    }
  }

  .contactUs {
    padding: 0px 30px;

    .desc {
      font-family: Roboto-Light;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .contact {
      font-family: Roboto-Light;
      font-size: 18px;
      margin-bottom: 20px;
      color: blue;
    }

    .homeBtn {
      position: absolute;
      bottom: 14px;
      left: 35vw;
    }
  }
}
